import React from "react";
import Link from "next/link";

import { Logo } from "../Logo";
import SubscribeForm from "../SubscribeForm";


import { useStore } from "../../context/state";

import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import Facebook from "@material-ui/icons/Facebook";
import Instagram from "@material-ui/icons/Instagram";
import Twitter from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import "./Footer.module.scss";

const menu1 = [
  { name: "About", link: "/about" },
  { name: "Order", link: "/delivery" },
  { name: "What’s New!", link: "/blog" },
  { name: "Visit", link: "/stores" },
  { name: "Press | Media Kit", link: "https://piroshkypiroshky.prowly.com/", blank: true},
  { name: "Contact", link: "/contacts" },
  { name: "Gift Card", link: "/gift-card-order" },



];

const menu2 = [
  { name: "Help Center", link: "/faq" },
  { name: "Privacy Policy", link: "/policy-center/privacy-policy" },
  { name: "Terms & Conditions", link: "/policy-center/terms-and-conditions" },
  { name: "Cookie Policy", link: "/policy-center/cookie-policy" },
  { name: "Refund Policy", link: "/policy-center/refund-policy" },
  { name: "Reheating instructions", link: "/static/img/menuV2.pdf"},
  { name: "Do Not Sell or Share My Personal Information", link: "/policy-center/data-policy"},

];

const locations = [
  
  {
    name: "Pike Place",
    address: (
      <span>
        1908 Pike Place <br />
        Seattle, WA 98101
      </span>
    ),
    link: "/stores/befd8129-fd5a-4eab-927e-450ccc9b54cf"
  },
  {
    name: "Century Square | 3rd & Pike",
    address: (
      <span>
        1501 4th Ave, Suite 110 <br />
    Seattle, WA 98101 <br/><span className="u-textColorRed u-fontSize7"></span>
      </span>
    ),
    link: "/stores/616fd6ec-9745-4693-934c-48b2627b0995/"
  },
  {
    name: "Columbia Tower",
    address: (
      <span>
        701 5th Ave <br />
        Seattle, WA 98108<br/>
      </span>
    ),
    link: "/stores/92718363-7219-48df-a580-01efc9f889f7/"
  },
  {
    name: "Convention Center",
    address: (
      <span>
        1000 Pine, Suite 433
        <br />
        Seattle, WA 98101
      </span>
    ),
    link: "/stores/Convention_center/"
  },
  {
    name: "Kent",
    address: (
      <span>
        26002 Pacific Hwy S.
        <br />
        Kent, WA 98032
        <br />
      </span>
    ),
    link: "/stores/piroshky_at_kent/"

  }
];


const SnapchatIcon = () => {
  return (
      <svg 
        fill={"#72704B"}
        xmlns="http://www.w3.org/2000/svg" 
        width="24" 
        height="24" 
        baseProfile="full" 
        viewBox="0 0 24.00 24.00">
          <path d="M12 20.447c-1.185.003-1.902-.504-2.534-.951-.454-.32-.881-.623-1.382-.706-1.153-.062-1.49.004-2.118.107-.105 0-.233-.023-.287-.206-.175-.753-.229-.958-.354-.977-1.345-.208-2.139-.513-2.297-.883-.034-.227.044-.324.153-.342 1.067-.176 2.016-.74 2.82-1.678.624-.726.93-1.42.963-1.496.16-.325.191-.597.097-.818-.173-.409-.747-.59-1.381-.797-.337-.133-.89-.414-.816-.802.053-.282.427-.479.95-.435.341.16.649.241.913.241.328 0 .487-.125.525-.16-.11-1.765-.206-3.291.185-4.167C8.605 3.757 11.084 3.554 12 3.552c.916.002 3.395.205 4.563 2.825.39.876.294 2.402.185 4.166.038.036.197.161.525.161.264 0 .572-.081.913-.241.523-.044.896.153.95.435.074.388-.48.669-.816.802-.634.206-1.208.388-1.381.797-.094.221-.063.493.097.818.033.077.339.77.962 1.496.805.938 1.754 1.502 2.822 1.678.108.018.186.115.152.342-.158.37-.952.675-2.297.883-.125.019-.179.224-.354.977-.054.183-.182.206-.287.206-.628-.103-.965-.169-2.118-.107-.501.083-.928.385-1.382.706-.632.447-1.349.954-2.534.95z"/>
      </svg>
    )
}

const TikTokIcon = () => {
  return (
    <svg
      fill={"#72704B"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="24px"
      height="24px"
    >
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
    )
}


export const Footer = (props) => {
  const { isMobile, dispatch } = useStore();

  if (props.hide) {
    return null
  }
  
  if (isMobile) {
    return (
      <footer className="footer u-sizeFullWidth">
        <div className="container">
          <div className="u-flexColumn u-alignItemsCenter">
            <div className="u-flexCenter u-marginBottom10 u-marginRightNegative1 u-marginTopNegative10">
              <a
                aria-label="Go to Facebook Page"
                href="https://www.facebook.com/PiroshkyBakery/"
                target="_blank"
              >
                <IconButton>
                  <Facebook color="action" />
                </IconButton>
              </a>
              <a
                aria-label="Go to Snapchat Page"
                href="https://www.snapchat.com/add/piroshkybakery/"
                target="_blank"
              >
                <IconButton>
                  <SnapchatIcon/>
                </IconButton>
              </a>
              <a
                aria-label="Go to TikTok Page"
                href="https://www.tiktok.com/@piroshkypiroshky/"
                target="_blank"
              >
                <IconButton>
                  <TikTokIcon/>
                </IconButton>
              </a>
             
              <a
                aria-label="Go to Youtube Page"
                href="https://www.youtube.com/@piroshkypiroshky"
                target="_blank"
              >
                <IconButton>
                  <YouTubeIcon color="action" />
                </IconButton>
              </a>
              <a
                aria-label="Go to Instagram Page"
                href="https://www.instagram.com/piroshkypiroshky/"
                target="_blank"
              >
                <IconButton>
                  <Instagram color="action" />
                </IconButton>
              </a>

              <a 
                aria-label="Go to Twitter Page"
                href="https://twitter.com/PiroshkyBakery" 
                target="_blank"
              >
                <IconButton>
                  <Twitter color="action" />
                </IconButton>
              </a>
            </div>
            <div className="u-sizeFullWidth u-marginBottom40 u-marginTop10">
              <SubscribeForm {...props}/>
            </div>
            <div className="u-flexColumn u-alignItemsCenter u-marginBottom20">

              <a
                aria-label="Send Email to cs@piroshkybakery.com"
                href="mailto: cs@piroshkybakery.com"
                className="u-flexCenter u-marginBottom8"
              >
                <Email
                  fontSize="small"
                  className="u-marginRight5 u-textColorNormal"
                />
                cs@piroshkybakery.com
              </a>
              <a aria-label="Call Piroshky customer Service Desk" href="tel:+1-206-764-1000" className="u-flexCenter">
                <Phone
                  fontSize="small"
                  className="u-marginRight5 u-textColorNormal"
                />
                206-764-1000
              </a>
            </div>
            <div>
              <Link aria-label="Go to Privacy Policy  Page" href="/privacypolicy" prefetch={false}>
                <span
                  className={
                    "menu-item u-flexCenter u-cursorPointer u-marginBottom15"
                  }
                >
                  Privacy Policy
                </span>
              </Link>
              <Link aria-label="Go to Terms and Conditions Page" href="/terms-and-conditions" prefetch={false}>
                <span
                  className={
                    "menu-item u-flexCenter u-cursorPointer u-marginBottom15"
                  }
                >
                  Terms of Use
                </span>
              </Link>
            </div>
            <Logo dispatch={dispatch} noText={!isMobile} size="large" />
                  
            <span className="copyright u-flexCenter u-justifyContentCenter u-marginTop20">
              Copyright © {new Date().getFullYear()} Piroshky Baking Company, LLC - All Rights
              Reserved.
            </span>
          </div>
        </div>
      </footer>
    );
  }

  return (
    <footer className="footer u-sizeFullWidth">
      <div className="container">
        <div className="footer-box">
          <div className="u-flex u-alignItemsFlexStart u-justifyContentSpaceBetween">
            <div className="u-flex u-alignItemsFlexStart">
              <nav className="app-header-menu u-flexColumn u-marginRight50">
                {menu1.map((v, i) => {
                  if (v.isPrivate && !isLogin) {
                    return null;
                  }
                  return (
                      <Link key={i} href={v.link}>
                          <a
                              target={v.blank ? "_blank" : undefined}
                            className={
                              "menu-item u-flexCenter u-cursorPointer u-marginBottom15"
                            }
                          >
                            {v.name}
                        </a>
                      </Link>
                  );
                })}
              </nav>

              <nav className="app-header-menu u-flexColumn u-marginRight20">
                {menu2.map((v, i) => {
                  if (v.isPrivate && !isLogin) {
                    return null;
                  }
                  return (
                       <Link key={i} href={v.link}>
                            <a
                              className={
                                "menu-item u-flexCenter u-cursorPointer u-marginBottom15"
                              }
                            >
                              {v.name}
                          </a>
                      </Link>
                  );
                })}
              </nav>

              <div className="app-header-menu u-flexColumn u-marginLeft50">
                <Grid container>
                  {locations.map((v, i) => {
                    return (
                      <Grid item xs={6}>
                          <Link href={v.link || "/"}>
                              <a>
                                  <span
                                    key={i}
                                    className={"menu-item u-flexColumn u-marginBottom10"}
                                  >
                                    <div
                                      className="u-fontWeightBold u-textUnderline u-cursorPointer"
                                      onClick={() => dispatch("/contacts")}
                                    >
                                      {v.name}
                                    </div>
                                    <span className="u-fontSize9">{v.address}</span>
                                  </span>
                              </a>
                          </Link>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>

              <div className="u-flexColumn u-alignItemsCenter">
                <div className="u-flexCenter u-marginBottom10 u-marginLefttNegative50 u-marginTopNegative10">
                  <a href="https://www.facebook.com/PiroshkyBakery/"
                    target="_blank"
                  >
                    <IconButton>
                      <Facebook color="action" />
                    </IconButton>
                  </a>

                  <a href="https://www.snapchat.com/add/piroshkybakery/"
                    target="_blank"
                  >
                    <IconButton>
                      <SnapchatIcon/>
                    </IconButton>
                  </a>

                  <a href="https://www.tiktok.com/@piroshkypiroshky/"
                    target="_blank"
                  >
                    <IconButton>
                      <TikTokIcon/>
                    </IconButton>
                  </a>

                  <a 
                    href="https://www.youtube.com/@piroshkypiroshky"
                    target="_blank"
                  >
                    <IconButton>
                      <YouTubeIcon color="action" />
                    </IconButton>
                  </a>

                  <a href="https://www.instagram.com/piroshkypiroshky/"
                    target="_blank"
                  >
                    <IconButton>
                      <Instagram color="action" />
                    </IconButton>
                  </a>

                  <a href="https://twitter.com/PiroshkyBakery" target="_blank">
                    <IconButton>
                      <Twitter color="action" />
                    </IconButton>
                  </a>
                  
                </div>
                <div className="u-flexColumn u-alignItemsCenter u-marginBottom20">
                  <a
                    href="mailto: cs@piroshkybakery.com"
                    className="u-flexCenter u-marginBottom8"
                  >
                    <Email
                      fontSize="small"
                      className="u-marginRight5 u-textColorNormal"
                    />
                    cs@piroshkybakery.com
                  </a>
                  <a href="tel:+1-206-764-1000" className="u-flexCenter">
                    <Phone
                      fontSize="small"
                      className="u-marginRight5 u-textColorNormal"
                    />
                    206-764-1000
                  </a>
                </div>
                <div className="u-sizeFullWidth">
                  <SubscribeForm {...props}/>
                </div>
              </div>
            </div>
          </div>
          <div className="u-flexColumn u-alignItemsCenter u-justifyContentCenter u-marginHorizontalAuto">
            <Logo  dispatch={dispatch} noText={!isMobile} size="large" />
          </div>

          <span className="copyright u-flexCenter u-justifyContentCenter u-marginTop0">
            Copyright © {new Date().getFullYear()} Piroshky Baking Company, LLC - All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
};
