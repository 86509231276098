import React, {useState, useEffect, useRef} from "react";
import Link from "next/link";

import { AccountWidget } from "./AccountWidget";

import { Logo } from "../Logo";
import { dataSourceV2 } from "commons-ui/core/DataSource";

import { useStore } from "../../context/state";
import {
  MenuItem,
  MenuButton,
  useMenuState,
  ControlledMenu,
  MenuDivider,
  MenuHeader
} from "@szhsin/react-menu";
import Notes from "@material-ui/icons/Notes";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import Person from "@material-ui/icons/Person";
import Menu from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Tooltip from "@material-ui/core/Tooltip";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/Phone";
import AddShoppingCart from "@material-ui/icons/AddShoppingCart";

import "./Header.module.scss";
import { Button } from "@material-ui/core";

export const Header = (props) => {
  const {
    productInCartCount,
    rootClassName,
    isCartOpen,
    disableCart,
    isLogin,
    hide
  } = props;

  const { ecommerceStoreId, dispatch, isMobile, apiPath, login } = useStore();

  const [accountOpen, setAccountOpen] = useState(null);
  const [deliveryList, setDeliveryList] = useState([]);
  const menu1 = useMenuState({ transition: true });
  const ref1 = useRef(null);

  const requestDelivery = () => {
    dataSourceV2(`ecommerce_store_delivery_option_list?ecommerce_store_id=${ecommerceStoreId}`, {url: apiPath})
        .then(v=> {
            if (!v.message && v.item_list && v.item_list.length) {
                setDeliveryList(v.item_list.filter(v => !v.is_hidden));
            }
        });
  };
  useEffect(() => {
    if (!isMobile) requestDelivery();
  }, []);

  if (hide) return null

  if (isMobile) {
    return (
      <header
        className={
          "app-header-main u-absolute u-flexCenter u-justifyContentSpaceBetween" +
          rootClassName
        }
      >
        <div className="container">
          <div className="app-header-wrapper u-height60 u-flexCenter u-justifyContentSpaceBetween u-reative">
            <div className="u-absolute0 u-sizeFull u-flexCenter u-justifyContentCenter">
              <Logo noText={isMobile} />
            </div>
            <IconButton
              color="inherit"
              onClick={() => dispatch("sideMenu", { isSideMenuOpen: true })}
            >
              <Menu />
            </IconButton>
            <div>
            <IconButton
              color="inherit"
              onClick={() =>
                isCartOpen || disableCart
                  ? console.log("disabled")
                  : dispatch("cart", { isOpen: true }, "open_cart")
              }
            >
              <Badge badgeContent={productInCartCount} color="secondary">
                <ShoppingCart className="u-textColorNormal" />
              </Badge>
              </IconButton>

              <Tooltip title={isLogin ? "Account" : "Login"}>
              <IconButton
                className=""
                onClick={(event) => setAccountOpen(event.currentTarget)}
              >
                <Person className="" />
              </IconButton>
            </Tooltip>
            <AccountWidget
              {...props}
              isLogin={isLogin}
              open={accountOpen}
              onClose={() => setAccountOpen(null)}
            />
            </div>
            
          </div>
        </div>
      </header>
    );
  }

  return (
    <header
      className={
        "app-header-main u-absolute u-flexCenter u-justifyContentSpaceBetween" +
        rootClassName
      }
    >
      <div className="container">
        <div className="app-header-wrapper u-height65 u-flexCenter">
          <div
            className="u-hide u-absolute0 u-sizeFull u-flexCenter u-justifyContentCenter"
            style={{ margin: "0 auto" }}
          ></div>
          <Notes
            className="icon-menu u-hide u-opacity80"
            onClick={() => dispatch("sideMenu", { isSideMenuOpen: true })}
          />
          <div className="app-header-logo u-flexCenter u-xs-hide u-zIndex2">
            <Link href="/">
              <a>
                <Logo noText={isMobile} />
              </a>
            </Link>
          </div>
          <div style={{color:"#D4454F"}}>Created by Us, Shared with You, Celebrated Together. PIROSHKYLOVE ♥</div>
          <div className="u-flexCenter">
              <Button
                  className="button-secondary-header u-marginRight10"
                  ref={ref1} onClick={() => menu1.toggleMenu(true)}
              >
                Order Now
              </Button>
              <ControlledMenu {...menu1}
                              anchorRef={ref1}
                              onMouseLeave={() => menu1.toggleMenu(false)}
                              onClose={() => menu1.toggleMenu(false)}
                  >
                  {
                      deliveryList.map((item, i) => {
                        let link = "/delivery/" + (item.slug || item["ecommerce_store_delivery_option_id"])
                        if (item.name && item.name.toLowerCase().includes("catering")) {
                          link = "/catering";
                        }
                          return (
                                <MenuItem>
                                    <MenuItemLink link={link} name={item.name}/>
                                </MenuItem>
                          ); 
                      })
                  }
                  
              </ControlledMenu>
            {/* <div className="u-flexColumn u-fontSize8 u-marginRight15">
              <a
                href="mailto: cs@piroshkybakery.com"
                className="u-flexCenter u-marginBottom2"
              >
                <Email
                  fontSize="small"
                  className="u-marginRight5 u-textColorNormal"
                />
                cs@piroshkybakery.com
              </a>
              <a href="tel:+1-206-764-1000" className="u-flexCenter">
                <Phone
                  fontSize="small"
                  className="u-marginRight5 u-textColorNormal"
                />
                206-764-1000  
              </a>
            </div> */} 

            <Tooltip title="Cart">
              <IconButton
                className="u-marginRight5"
                disabled={isCartOpen || disableCart}
                onClick={() => dispatch("cart", { isOpen: true }, "open_cart")}
              >
                <Badge badgeContent={productInCartCount} color="secondary">
                  <ShoppingCart />
                </Badge>
              </IconButton>
            </Tooltip>

            <Tooltip title={isLogin ? "Account" : "Login"}>
              <IconButton
                className=""
                onClick={(event) => setAccountOpen(event.currentTarget)}
              >
                <Person className="" />
              </IconButton>
            </Tooltip>
            <AccountWidget
              {...props}
              isLogin={isLogin}
              open={accountOpen}
              onClose={() => setAccountOpen(null)}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

function MenuLink(props) {
  const activeClass = props.isActive ? " active" : "";
  const item = (
      <span
        className={
          "menu-item u-flexCenter" +
          activeClass +
          " " +
          (props.className || "")
        }
      >
          <b>{props.name}</b>
      </span>
  );
  if (!props.link) {
      return item;
  }
  return (
    <Link href={props.link}>
      <a>
        {item}
      </a>
    </Link>
  );
}

function MenuItemLink(props) {
  if (!props.link) {
      return <div>{props.name}</div>;
  }
return (
  <Link href={props.link}>
    <a>
      {props.name}
    </a>
  </Link>
);
}

