import React, { useEffect } from "react";
import { useRouter } from "../hooks/useRouter";
import cookie from "react-cookie";
import { getMenuUrl } from "../utils"
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { SideMenu } from "../components/SideMenu";
import { CookieBanner } from "../components/CookieBanner";
import { LicenseAgreementDialog } from "../components/LicenseAgreementDialog";
import { OrderNotification } from "../components/OrderNotification";
import NotificationHeader from "../components/NotificationHeader";
import ProductDescriptionWidget from "../components/ProductDescriptionWidget";
import Notification from "../components/Notification";
import { DeliverySelectWidget } from "../components/DeliverySelectWidget";
import SubscribeWidget from "../components/SubscribeWidget";

import { useStore } from "../context/state";

import {
  ecommerceStoreGroupId,
  ecommerceStoreId,
  blogEcommerceStoreId,
  blogEcommerceStoreGroupId,
  apiPath,
  mediaPath,
  socketPath,
  recaptchaKey,
} from "../constants";

import { dataSourceV2 } from "commons-ui/core/DataSource";
import DialogCore from "commons-ui/core/Dialog";

import Cart from "ecommerce-commons-ui/core/Cart";

const logout = (dispatch) => {
  cookie.remove("token", { path: "/" });
  cookie.remove("token_refresh", { path: "/" });
  if (dispatch) {
    dispatch();
  }
};

export const AppWrapper = (props) => {
  const {
    style,
    sideMenu,
    device,
    cart,
    store_settings,
    category_widget,
    location_widget,
    notification,
    dialog_core,
    customer,
    login,
    dispatch,
  } = useStore();

  const isLogin =
    customer.status === "payment" || customer.status === "confirmation"
      ? false
      : login.token;

  const isPreLogin = login.token && !isLogin;

  const onResize = () => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const isMobile = width < 800;
    dispatch("style", { width, height, isMobile });
  };

  const checkRequiredData = () => {
    if (!login.token) {
      return;
    }

    dataSourceV2("customer_get", { url: apiPath }).then((v) => {
      if (v.item) {
        //dispatch("customer", v.item);
      } else if (v.status) {
        logout(() => dispatch("/"));
      }
    });
  };

  const requestStoreSettings = () => {
    dataSourceV2(`ecommerce_store_settings_get?ecommerce_store_id=${ecommerceStoreId}`, { url: apiPath }).then((v) => {
      if (v.item) {
        dispatch("store_settings", v.item);
      }
    });
  };

  useEffect(() => {
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    checkRequiredData();
  }, [isLogin]);

  useEffect(() => {
    checkRequiredData();
    requestStoreSettings();
  }, []);

  useEffect(() => {
    if (customer.force_update) {
      checkRequiredData();
    }
  }, [customer.force_update]);

  const location = useRouter();
  const url = location.pathname;
  const mobileClass = style.isMobile ? " mobile" : "";
  const deviceId = device.device_id;
  const deviceSimpleId = device.device_simple_id;
  const locationId = ((cart || {}).deliveryItem || {}).location_id;

  const hideHeader = url.includes("-print")

  const isMobile = style.isMobile;
  if (isMobile === undefined) {
    return null;
  }

  const propsDefault = {
    ...login,
    ...style,
    dispatch,
    device,
    store_settings,
    cart,
    isLogin,
    location_widget,
    category_widget,
    deviceId,
    customer,
    ecommerceStoreGroupId,
    ecommerceStoreId,
    blogEcommerceStoreId,
    blogEcommerceStoreGroupId,
    deviceSimpleId,
    mediaPath,
    apiPath,
    recaptchaKey,
    socketPath,
    locationId
  };

  console.log(123, cart)

  return (
    <div style={{ minHeight: style.height }}>
      <SideMenu isOpen={(sideMenu || {}).isSideMenuOpen} />

      <Header
        {...propsDefault}
        hide={hideHeader}
        isPreLogin={isPreLogin}
        productInCartCount={cart.items.reduce((a, b) => a + (b.count || 1), 0)}
        url={url}
      />


      <div
        className={"app-content u-paddingTop65" + mobileClass}
        style={{ minHeight: style.height }}
      >
      <NotificationHeader text={store_settings.client_notification_message}
                        hide={hideHeader}
                        type={store_settings.client_notification_message_type}/>


        {props.children}
      </div>

      <DialogCore {...dialog_core} fullScreen={isMobile} dispatch={dispatch} />
      <Notification {...notification} dispatch={dispatch} />
      <ProductDescriptionWidget
        {...propsDefault}
        dispatch={dispatch}
        location={location}
        isFullscreen={isMobile}
      />
      <LicenseAgreementDialog
        {...propsDefault}
        dispatch={dispatch}
        location={location}
      />
      <Cart {...cart} {...propsDefault} onContinue={() => location.push(getMenuUrl(cart.deliveryItem || {}))}/>


      <DeliverySelectWidget/>

      <CookieBanner/>

      <SubscribeWidget {...propsDefault}/>
      <div>
        <Footer {...propsDefault} hide={hideHeader} isMobile={isMobile} dispatch={dispatch} />
      </div>
    </div>
  );
};
